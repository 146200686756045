@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.c-dropdown {
  @apply appearance-none rounded-none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23FFFFFF'><polygon points='0,0 100,0 50,50'/></svg>");
  background-size: 10px;
  background-position: calc(100% - 15px) 21px;
  background-repeat: no-repeat;
}
